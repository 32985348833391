import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../font/style.css'
import './index.css'

class IndexPage extends React.Component {
  state = {
    iconData: null,
    term: "apple"
  }

  componentDidMount(){
    this.fetchByTerm("apple");
  }

  fetchByTerm(term){
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      fetch(`https://api.svgapi.com/v1/Ty5WcDa63E/list/?search=${term}&limit=20`).then(res => res.json()).then(resJson => {
        this.setState({iconData: resJson});
      });
    }, 200);
  }

  renderIcons(){
    if(this.state.iconData){
      return this.state.iconData.icons.map((icon, i) => {
        return (<img src={icon.url} key={icon.id}/>);
      })
    }
  }

  render(){
    return (
      <Layout>
        <SEO title="svgapi - Reliable SVG Icon API in PD&CC" description="Rest API to search and list wide variety of icons. Build awesome stuff with free and maker-friendly pricing points."/>
        <div className="register">
          <div className={this.state.registered ? "side registered" : "side"}>
            <div className="sideInner">
              <div className="header">
                <a href="/"><img src={require("../images/logo_white.svg")} className="logo"/></a>
              </div>
              <div className="sideInfo">
                <h1>Access to 200k+ SVG Icons</h1>
                <p>Rest API to search and list wide variety of icons in Public Domain & CC (no-attibution) license.</p>
                <p>Build awesome stuff with free and maker-friendly pricing points.</p>
              </div>
              <div className="sideLinks">
                <a href="https://panel.svgapi.com">Admin</a>
                <a href="/pricing">Pricing</a>
                <a href="/documentation">Documentation</a>
                <a href="/contact">Contact Us</a>
              </div>
            </div>

          </div>


          <div className="sideHolder"></div>
          <div className="form">
            <div className="formInner">

              <h2>Test Things Out</h2>
              <p>Change the `term` parameter in GET request below to see some sample output and type of returned vector icons. For more details, see the <a href="/documentation">documentation page</a>.</p>

              <div className="getRequest">
                GET /v1/search?term=<input type="text" placeholder="apple" onChange={(e) => { this.setState({term: e.target.value}); this.fetchByTerm(e.target.value); }} defaultValue="apple"/>
              </div>

              <div className="demo">
                <textarea value={this.state.iconData ? JSON.stringify(this.state.iconData, null, 2) : "fetching..."}>
                </textarea>

                <div className="requestResult">
                  {this.renderIcons()}
                </div>
              </div>

              <div className="questions">
                <h2>Your Questions</h2>
                <p>Is svgapi for you? Let's talk about your commonly asked questions, and you can always get in <a href="mailto:info@svgapi.com">contact with us</a> to ask your questions or learn more about our product.</p>

                <strong>How am I going to be charged?</strong>
                <p>Depending on the pricing you choose, our payment processor will charge your card (or paypal) monthly. If the recurring charge is not successful, we will keep your services for 7 more days, and try charging during this time period. If not, your domain license will stop. You can ask for a manual charge on the panel after this period to keep the service going.</p>

                <strong>Can I use for Commercial Projects?</strong>
                <p>Yes, the icons you are being served is Public domain & Creative Commons (no-attibution) you can also toggle `?nc=true` in order to get icons for personal use or icons that need to be given attribute to author. Also company brand icons like Google or Apple's icon are not served for transformative use, these icons should be used respective to the companies terms.</p>

                <strong>Is the API reliable, will it be randomly down?</strong>
                <p>We serve from AWS. It is pretty reliable, (99.9% uptime) and use Cloudflare as CDN. Unless there is a technical problem on our end, API should work fast and properly for every user worldwide. If there will be an outage for longer than 24 hours, we won't be charging our users that month.</p>

                <strong>Can I use your CDN to serve my users indefinitely?</strong>
                <p>We don't recommend it, as some vectors might be deleted in our end, because of quality or duplicate reasons. We encourage you to store the vectors you will be serving for long time in your own server/cdn.</p>

                <strong>Can you help me intergrate the API?</strong>
                <p>We won't be given support for integrating in your system. It is a generic REST Api, and you can find someone to commision for integrating our API, we would also love to recommend developers for you if you contact us.</p>

                <strong>Can I cache/store/crawl the API via backend?</strong>
                <p>Yes, by enabling development mode, you can make curl requests to your endpoint from any scripting language. Make sure to keep your domain_key if you keep your development mode enabled.</p>
              </div>
              <a href="/pricing"><div className="start"><span>Get Started</span></div></a>
              <footer style={{fontSize: "0.9em", paddingTop: 20, paddingBottom: 30}}>
                Copyright © {new Date().getFullYear()}, {` `} Dream Oriented Ltd.<br/>
                <small>We collect payments using Paddle.com's services with the company name Dream Oriented Ltd.</small>
                <br/>
                <small><a href="https://paddle.com/legal-buyers/" target="_blank">Paddle Buyer Terms and Conditions</a></small>
              </footer>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
